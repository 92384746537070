
/************************************************************************/
/* Generelles ***********************************************************/

.CurvePanel {
    background: #ffffff 0% 0% no-repeat padding-box;
}

.WidgetPanel {
    background: #F7F7F7 0% 0% no-repeat padding-box;
    text-align: center;
}

.WidgetPanel > * {
    display: inline-block;
}

.NavPanel .Caption {
    width: 170px;
    height: 61px;
    text-align: left;
    font: normal normal bold 26px/25px "Open Sans";
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #535353;
    text-transform: uppercase;
    opacity: 1;
}

.NavPanel .Colorbox { 
    background: #B0AADB 0% 0% no-repeat padding-box;
    opacity: 1;
}

.NavPanel .Optionen {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #535353;
    text-transform: uppercase;
    opacity: 1;
}

.NavPanel .Option.aktiv {
    color: #9A92D1;
    background: #DEDBF0 0% 0% no-repeat padding-box;
    border-radius: 25px 0px 0px 25px;
    opacity: 1;
}


/************************************************************************/
/* Standardansicht ******************************************************/

@media screen and (min-width: 1000px) {

    .WidgetPanel {
        position: absolute;
        top: 0px;
        left: 366px;
        right: 0px;
        opacity: 1;
        padding-left: 145px;
        padding-right: 145px;
        padding-top: 196px;
        padding-bottom: 196px;
    }

    .NavPanel {
        position: absolute;
    }

    .NavPanel .Caption {
        top: 64px;
        left: 66px;
        position: absolute;
    }

    .NavPanel .Colorbox { 
        position: absolute;
        top: 68px;
        left: -84.125px;
        width: 139px;
        height: 53px;
    }

    .NavPanel .Optionen {
        position: absolute;

        top: 196px;
        left: 58px;
        width: 400px;
        height: 36px;
        font: normal normal bold 26px/36px "Open Sans";
    }

    .NavPanel .Option {
        padding-left: 19px;
        margin-top: 30px;
    }

}

/************************************************************************/
/* Kleinere Paddings bei schmalen Desktops*******************************/

@media screen and (max-width: 1700px){

    .WidgetPanel {
        padding-left: 75px;
        padding-right: 75px;
        padding-top: 120px;
        padding-bottom: 120px;
    }

}

/************************************************************************/
/* Mobile Ansicht *******************************************************/

@media screen and (max-width: 1000px){
    
    .WidgetPanel {
        padding: 1ch;
    }

    .NavPanel {
        padding: 5px;
    }

    .NavPanel .Caption {
        margin-left: 1ch;
        display: inline-block;
    }

    .NavPanel .Colorbox { 
        width: 53px;
        height: 53px;
        float: left;
    }

    .NavPanel .Optionen {
        font: normal normal bold 22px/30px "Open Sans";
    }

    .NavPanel .Option {
        padding-left: 19px;
        margin-top: 5px;
        margin-left: 2ch;
    }

}

