
.TempCurve {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    width: 404px;
    height: 322px;
    border-radius: 25px;
    padding: 25px;
    margin: 20px;
}

.TempCurve-Caption {
    text-align: left;
    font: normal normal normal 18px/24px "Open Sans";
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #9A92D1;
    text-transform: uppercase;
    opacity: 1;
}

.TempCurve-Value {
    text-align: left;
    font: normal normal bold 46px/62px "Open Sans";
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #535353;
    text-transform: uppercase;
    opacity: 1;
}

.TempCurve-ValueTime {
    margin-left: 1ex;
    text-align: left;
    font: normal normal normal 12px/18px "Open Sans";
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #535353;
    text-transform: none;
    opacity: 1;
}

.TempCurve-Canvas {
    min-height: 200px;
    min-width: 300px;
    padding: auto;

}
